import './App.css';
import './style/style.css';
import './style/formStyle.css';
import './style/responsiveStyle.css';
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import Form from './components/Form';
import Dashboard2 from './components/Dashboard2';
import Thanks from './components/Thanks';
import Login from './components/Login';
import Profile from './components/Profile';
import Form2 from './components/Form2';
import Dashboard from './components/Dashboard';
import StudentLogin from './components/StudentLogin';
import StudentProfile from './components/StudentProfile';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import Thanks2 from './components/Thanks2';

function App() {
  return (
    <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/form' element={<Form />} />
          <Route path='/admin_dashboard' element={<Dashboard2/>} />
          <Route path='/thankyou' element={<Thanks />} />
          <Route path='/admin_login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/student_profile' element={<StudentProfile/>} />
          <Route path='/form2' element={<Form2 />} />
          <Route path='/student_dashboard' element={<Dashboard/>} />
          <Route path='/student_login' element={<StudentLogin/>} />
          <Route path='/forgot_password' element={<ForgotPassword/>} />
          <Route path='/change_password' element={<ChangePassword/>} />
          <Route path='/thanks' element={<Thanks2 />} />
        </Routes>
    </div>
  );
}

export default App;
