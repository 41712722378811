import React, { useState, useEffect } from 'react';
import image1 from '../images/iiclogo-1 1.png';
import image2 from '../images/Line 10.png';
import image3 from '../images/cgc_venturenest_logo_n1 2.png';
import image4 from '../images/Vector 5.png';
import image5 from '../images/Vector 9.png';
import image6 from '../images/Vector 6.png';
import image7 from '../images/Vector 10.png';
import image8 from '../images/Vector 13.png';
import image9 from '../images/Vector 12.png';
import image10 from '../images/Vector 14.png';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Form() {

    const [alert, setAlert] = useState(null);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState();
    const [aadhar, setAadhar] = useState();
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [teamMembers, setTeamMembers] = useState("");
    const [desc, setDesc] = useState("");
    const [workExperience, setWorkExperience] = useState();
    const [targetAudience, setTargetAudience] = useState("");
    const [registered, setRegistered] = useState("No");
    const [companyName, setCompanyName] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [otherDetails, setOtherDetails] = useState("");
    const [pdfFile, setPdfFile] = useState(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const navigate = useNavigate();

    const [authToken, setAuthToken] = useState(null);
    const [authTokenExpiry, setAuthTokenExpiry] = useState(null);

    useEffect(() => {
        getAccessToken();
    }, []);

    const getAccessToken = async () => {
        try {
            const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
                headers: {
                    "api-token": "QcbmmQtxMFEXBbZN0OnFjG1lpJaOf1FSROjj5K9WMkJwkllhMQBkUIXv70S1aqabX2k",
                    "user-email": "shivduttdhadwal@gmail.com",
                    Accept: "application/json",
                },
            });

            const data = await response.json();
            setAuthToken('Bearer ' + data.auth_token);
            setAuthTokenExpiry(new Date().getTime() + (4 * 60 * 60 * 1000));

        }
        catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (authToken && authTokenExpiry && new Date().getTime() > authTokenExpiry) {
            getAccessToken();
        }
    }, [authToken, authTokenExpiry]);


    useEffect(() => {
        getStates();
    }, [authToken])

    const getStates = async () => {

        if (!authToken) {
            console.error('AuthToken is null, cannot fetch states');
            return;
        }

        const response = await fetch("https://www.universal-tutorial.com/api/states/india",
            {
                headers: {
                    Authorization: authToken,
                    Accept: "application/json"
                }
            }
        )

        const data = await response.json();
        console.log(data);
        setStates(data);
    }

    const getCities = async (updatedState) => {
        try {
            const response = await fetch(`https://www.universal-tutorial.com/api/cities/${updatedState}`,
                {
                    headers: {
                        Authorization: authToken,
                        Accept: "application/json"
                    }
                }
            )

            const data = await response.json();
            console.log(data);
            setCities(data);
        }
        catch (error) {
            console.error('Error fetching cities:', error);
        }
    }

    const formatWithLeadingZero = (number) => {
        return number < 10 ? '0' + number : number;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const currentDateObj = new Date();
        const day = formatWithLeadingZero(currentDateObj.getDate());
        const month = formatWithLeadingZero(currentDateObj.getMonth() + 1);
        const year = currentDateObj.getFullYear();
        let hours = currentDateObj.getHours();
        const minutes = formatWithLeadingZero(currentDateObj.getMinutes());
        const period = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedDate = `${day}/${month}/${year} ${formatWithLeadingZero(hours)}:${minutes} ${period}`;

        const formData = new FormData();
        formData.append("email", email);
        formData.append("name", name);
        formData.append("number", number);
        formData.append("aadhar", aadhar);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("team_members", teamMembers);
        formData.append("desc", desc);
        formData.append("workExperience", workExperience);
        formData.append("target_audience", targetAudience);
        formData.append("registered", registered);
        formData.append("companyName", companyName);
        formData.append("companyType", companyType);
        formData.append("other_details", otherDetails);
        formData.append("time", formattedDate);
        formData.append("pdf_file", pdfFile);

        console.log([...formData]);

        const response1 = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/store-data", {
            method: "POST",
            body: formData
        });
        const data1 = await response1.json();
        console.log(data1);

        if (data1.data.success) {
            setAlert({ msg: "Thank you for the registration", type: "success" });
            navigate("/thankyou");
        }
        else {
            setAlert({ msg: data1.message, type: "danger" });
        }

        setTimeout(() => {
            setAlert(null);
        }, 2000);

    }

    const handleChange1 = (e) => {
        setEmail(e.target.value);
    }

    const handleChange2 = (e) => {
        setName(e.target.value);
    }

    const handleChange3 = (e) => {
        setNumber(e.target.value);
    }

    const handleChange4 = (e) => {
        setAadhar(e.target.value);
    }

    const handleChange5 = (e) => {
        setState(e.target.value);
        getCities(e.target.value);
    }

    const handleChange6 = (e) => {
        setCity(e.target.value);
    }

    const handleChange7 = (e) => {
        setTeamMembers(e.target.value);
    }

    const handleChange8 = (e) => {
        setDesc(e.target.value);
    }

    const handleChange9 = (e) => {
        setWorkExperience(e.target.value);
    }

    const handleChange10 = (e) => {
        setTargetAudience(e.target.value);
    }

    const handleChange11 = (e) => {
        setRegistered("Yes");
    }

    const handleChange15 = (e) => {
        setRegistered("No");
    }

    const handleChange12 = (e) => {
        setCompanyName(e.target.value);
    }

    const handleChange13 = (e) => {
        setOtherDetails(e.target.value);
    }

    const handleChange14 = (e) => {
        setPdfFile(e.target.files[0]);
    }

    const handleChange16 = (e) => {
        setCompanyType(e.target.value);
    }


    return (
        <div>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <header className="header form_container">
                <div className="container ">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-8">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-6">
                                    <img src={image1} alt="" />
                                </div>
                                <div className="col-1">
                                    <img src={image2} alt="" className="img_width" />
                                </div>
                                <div className="col-5">
                                    <img src={image3} alt="" />
                                </div>
                            </div>
                        </div>
                        <h2>The CGC <span className="span_heading">Venture Vault</span> : CGC VentureNest : 4th and 5th October, 2024 : Application Form</h2>
                        <h3 className="text-center some">Please submit the application form as below :</h3>
                    </div>
                </div>
                <div className="pic1">
                    <img src={image4} alt="" />
                </div>
                <div className="pic2">
                    <img src={image5} alt="" />
                </div>
            </header>

            <section className="section form_container">
                <div className="container form_sec">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="inside_form_sec">

                                <form onSubmit={handleSubmit}>

                                    <div className="form_fields">
                                        <label htmlFor="Email" className="form-label">Email <span className="star">*</span></label>
                                        <input required type="email" value={email} onChange={handleChange1} placeholder="Your email address" className="form-control" id="Email" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="name" className="form-label">Name of the applicant <span className="star">*</span></label>
                                        <input required type="text" value={name} onChange={handleChange2} placeholder="Your answer" className="form-control" id="name" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="number" className="form-label">Mobile Number (WhatsApp) <span className="star">*</span></label>
                                        <input required type="number" value={number} onChange={handleChange3} placeholder="Your answer" className="form-control" id="number" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="aadhar" className="form-label">Aadhar Number <span className="star">*</span></label>
                                        <input required type="number" value={aadhar} onChange={handleChange4} placeholder="Your answer" className="form-control" id="aadhar" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="State" className="form-label">State <span className="star">*</span></label>
                                        <select
                                            required
                                            value={state}
                                            onChange={handleChange5}
                                            className="form-control"
                                            id="State"
                                        >
                                            <option value="">Select State</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state.state_name}>
                                                    {state.state_name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="City" className="form-label">City <span className="star">*</span></label>
                                        <select
                                            required
                                            value={city}
                                            onChange={handleChange6}
                                            className="form-control"
                                            id="State"
                                        >
                                            <option value="">Select City</option>
                                            {cities.map((city, index) => (
                                                <option key={index} value={city.city_name}>
                                                    {city.city_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="team_members" className="form-label">Team members details, if any (Name, Email, Ph No.) </label>
                                        <input type="text" value={teamMembers} onChange={handleChange7} placeholder="Your answer" className="form-control" id="team_members" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="desc" className="form-label">Brief description of the Product/Services/ Technology/Business, you plan to submit (not more than in 1 page)<span className="star">*</span></label>
                                        <textarea required value={desc} onChange={handleChange8} placeholder="Your answer" className="form-control" id="desc" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="working" className="form-label">How long have you been working on the idea?(in months) <span className="star">*</span></label>
                                        <input required type="number" value={workExperience} onChange={handleChange9} placeholder="Your answer" className="form-control" id="working" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="target_audience" className="form-label">Who is your target audience/customer?<span className="star">*</span></label>
                                        <input required type="text" value={targetAudience} onChange={handleChange10} placeholder="Your answer" className="form-control" id="target_audience" />
                                    </div>

                                    <div className="form_fields">
                                        <label htmlFor="form-check" className="form-label">Are you are a registered company under companies act? <span className="star">*</span></label>

                                        <div className="form-check" id="formcheckid">
                                            <input className="form-check-input" value={registered} onClick={handleChange11} type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                            <label className="form-check-label radio_label" htmlFor="flexRadioDefault1">
                                                Yes
                                            </label>

                                            <input className="form-check-input" type="radio" name="flexRadioDefault2" value={registered} onClick={handleChange15} id="flexRadioDefault2" />
                                            <label className="form-check-label radio_label" htmlFor="flexRadioDefault2">
                                                No
                                            </label>
                                        </div>
                                    </div>

                                    {registered === "Yes" && <div className="form_fields">
                                        <label htmlFor="company" className="form-label">Name of Company <span className="star">*</span></label>
                                        <input required type="text" value={companyName} onChange={handleChange12} placeholder="Your answer" className="form-control" id="company" />
                                    </div>}

                                    {registered === "Yes" && <div className="form_fields">
                                        <label for="mention" className="form-label">Mention its type like Pvt Ltd. Or LLP. <span className="star">*</span></label>
                                        <input required type="text" value={companyType} onChange={handleChange16} placeholder="Your answer" className="form-control" id="mention" />
                                    </div>}

                                    {registered === "Yes" && <div className="form_fields">
                                        <label htmlFor="other_details" className="form-label">Any other details (award, achievement, patent etc.) which would help in evaluating your proposal<span className="star">*</span></label>
                                        <input required type="text" value={otherDetails} onChange={handleChange13} placeholder="Your answer" className="form-control" id="other_details" />
                                    </div>}

                                    <div className="form_fields">
                                        <label className="form-label">Please submit your pitch deck for the evaluation <span className="star">*</span></label>
                                        <input required type="file" accept=".pdf" onChange={handleChange14}className="form-control file_field" id="pitch_deck" />

                                        <label htmlFor="pitch_deck" className="link"><i className="fa-sharp fa-solid fa-file-arrow-up"></i></label>
                                    </div>

                                    <div className="form_fields">
                                        <label className="form-label">Declaration <span className="star">*</span></label>

                                        <div className="form-check flex_form_check">
                                            <input className="form-check- flex_form_input" type="checkbox" required id="flexCheckDefault" />
                                            <label className="form-check-label radio_label" htmlFor="flexCheckDefault">
                                                I/We hereby declare that all information along with mentioned documents & facts are true, correct to the best of my / our knowledge and nothing has been concealed so far.
                                            </label>
                                        </div>
                                    </div>

                                    <button type="submit" className="mybtn" id="btnid">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pic3">
                    <img src={image6} alt="" />
                </div>
                <div className="pic4">
                    <img src={image7} alt="" />
                </div>
                <div className="pic5">
                    <img src={image8} alt="" />
                </div>
                <div className="pic6">
                    <img src={image9} alt="" />
                </div>
                <div className="pic7">
                    <img src={image10} alt="" />
                </div>
            </section>

            <footer>
                <Footer />
            </footer>
        </div>
    )
}
