import React, { useState, useRef, useEffect } from 'react'

const Modal = ({ selectedRow, setSelectedRow, changeAlert }) => {
    const [flag, setFlag] = useState(false);
    const closeRef = useRef(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [teamMembers, setTeamMembers] = useState("");
    const [description, setDescription] = useState("");
    const [workExperience, setWorkExperience] = useState("");
    const [targetAudience, setTargetAudience] = useState("");
    const [registered, setRegistered] = useState("No");
    const [companyName, setCompanyName] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [otherDetails, setOtherDetails] = useState("");
    const [pdfFile, setPdfFile] = useState(null);

    const [isValid, setIsValid] = useState(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [alert, setAlert] = useState(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [authToken, setAuthToken] = useState(null);
    const [authTokenExpiry, setAuthTokenExpiry] = useState(null);

    useEffect(() => {
        if (selectedRow) {
            setName(selectedRow.name || "");
            setEmail(selectedRow.email || "");
            setNumber(selectedRow.number || "");
            setAadhar(selectedRow.aadhar || "");
            setState(selectedRow.state || "");
            setCity(selectedRow.city || "");
            setTeamMembers(selectedRow.team_members || "");
            setDescription(selectedRow.description || "");
            setWorkExperience(selectedRow.workExperience || "");
            setTargetAudience(selectedRow.target_audience || "");
            setRegistered(selectedRow.registered || "No");
            setCompanyName(selectedRow.companyName || "");
            setCompanyType(selectedRow.companyType || "");
            setOtherDetails(selectedRow.other_details || "");
        }
    }, [selectedRow]);

    useEffect(() => {
        getAccessToken();
        console.log("registered", selectedRow.registered)
    }, []);

    const getAccessToken = async () => {
        try {
            const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
                headers: {
                    "api-token": "QcbmmQtxMFEXBbZN0OnFjG1lpJaOf1FSROjj5K9WMkJwkllhMQBkUIXv70S1aqabX2k",
                    "user-email": "shivduttdhadwal@gmail.com",
                    Accept: "application/json",
                },
            });

            const data = await response.json();
            setAuthToken('Bearer ' + data.auth_token);
            setAuthTokenExpiry(new Date().getTime() + (4 * 60 * 60 * 1000));

        }
        catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (authToken && authTokenExpiry && new Date().getTime() > authTokenExpiry) {
            getAccessToken();
        }
    }, [authToken, authTokenExpiry]);


    useEffect(() => {
        getStates();
    }, [authToken])

    const getStates = async () => {

        if (!authToken) {
            console.error('AuthToken is null, cannot fetch states');
            return;
        }

        const response = await fetch("https://www.universal-tutorial.com/api/states/india",
            {
                headers: {
                    Authorization: authToken,
                    Accept: "application/json"
                }
            }
        )

        const data = await response.json();
        console.log(data);
        setStates(data);

    }

    const getCities = async (updatedState) => {
        try {
            const response = await fetch(`https://www.universal-tutorial.com/api/cities/${updatedState}`,
                {
                    headers: {
                        Authorization: authToken,
                        Accept: "application/json"
                    }
                }
            )

            const data = await response.json();
            console.log(data);
            setCities(data);
        }
        catch (error) {
            console.error('Error fetching cities:', error);
        }
    }

    
    
    const handleChange1 = (e)=>{
        setName(e.target.value);
    }
    
    const handleChange2 = (e) => {
        setEmail(e.target.value);
        setIsValid(emailRegex.test(e.target.value));
    };

    const handleChange3 = (e)=>{
        setNumber(e.target.value)
    }

    const handleChange4 = (e)=>{
        setAadhar(e.target.value)
    }

    const handleChange5 = (e) => {
        setState(e.target.value);
        setFlag(true);
        getCities(e.target.value);
    }

    const handleChange6 = (e) => {
        setCity(e.target.value);
    }

    const handleChange7 = (e)=>{
        setTeamMembers(e.target.value)
    }

    const handleChange8 = (e)=>{
        setDescription(e.target.value)
    }

    const handleChange9 = (e)=>{
        setWorkExperience(e.target.value)
    }

    const handleChange10 = (e)=>{
        setTargetAudience(e.target.value)
    }

    const handleChange11 = (e) => {
        setRegistered(e.target.value);
        setCompanyName("");
        setCompanyType("");
        setOtherDetails("");
    }

    const handleChange12 = (e)=>{
        setCompanyName(e.target.value)
    }

    const handleChange13 = (e)=>{
        setCompanyType(e.target.value)
    }

    const handleChange14 = (e)=>{
        setOtherDetails(e.target.value)
    }

    const handleChange15 = (e) => {
        setPdfFile(e.target.files[0]);
    }

    

    function counting(num) {
        return String(num).split('').reduce(
            (count, digit) => count + 1, 0
        );
    }

    const editData = async () => {

        if (counting(number) != 10) {
            setAlert({ msg: "Invalid Phone Number", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (counting(aadhar) != 12 && aadhar) {
            setAlert({ msg: "Invalid Aadhar Number", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if(name===""){
            setAlert({ msg: "Name can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (isValid) {
            console.log('Email is valid');
        }else{
            setAlert({ msg: "Invalid Email", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if(selectedRow.email===""){
            setAlert({ msg: "Email can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (companyName === "" && registered==="Yes") {
            setAlert({ msg: "Company Name can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (companyType === "" && registered==="Yes") {
            setAlert({ msg: "Company Type can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (otherDetails === "" && registered==="Yes") {
            setAlert({ msg: "Company Details can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("aadhar", aadhar);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("team_members", teamMembers);
        formData.append("desc", description);
        formData.append("workExperience", workExperience);
        formData.append("target_audience", targetAudience);
        formData.append("registered", registered);
        formData.append("companyName", companyName);
        formData.append("companyType", companyType);
        formData.append("other_details", otherDetails);

        if (pdfFile !== null) {
            formData.append("pdf_file", pdfFile);
        }

        const response = await fetch(`https://wpi.cgc.ac.in/wp-json/myplugin/v1/update_data?email=${selectedRow.email}`, {
            method: "POST",
            body: formData
        })

        const data = await response.json();
        console.log(data);

        if (data.status === "success") {
            changeAlert();
        }

        closeRef.current.click();
    }

    const handleSaveChanges = (e) => {
        e.preventDefault();
        editData();
    }

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        };

        const inputElement = inputRef.current;
        const inputElement2 = inputRef2.current;
        const inputElement3 = inputRef3.current;
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel);
            inputElement.addEventListener('mousewheel', handleWheel);
        }
        if (inputElement2) {
            inputElement2.addEventListener('wheel', handleWheel);
            inputElement2.addEventListener('mousewheel', handleWheel);
        }
        if (inputElement3) {
            inputElement3.addEventListener('wheel', handleWheel);
            inputElement3.addEventListener('mousewheel', handleWheel);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('wheel', handleWheel);
                inputElement.removeEventListener('mousewheel', handleWheel);
            }
            if (inputElement2) {
                inputElement2.removeEventListener('wheel', handleWheel);
                inputElement2.removeEventListener('mousewheel', handleWheel);
            }
            if (inputElement3) {
                inputElement3.removeEventListener('wheel', handleWheel);
                inputElement3.removeEventListener('mousewheel', handleWheel);
            }
        };
    }, []);

    return (
        <div>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <div className='modal-body'>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleChange1}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleChange2}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="number" className="form-label">Number</label>
                        <input
                            type="number"
                            className="form-control"
                            id="number"
                            name="number"
                            value={number}
                            onChange={handleChange3}
                            ref={inputRef}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="aadhar" className="form-label">Aadhar</label>
                        <input
                            type="number"
                            className="form-control"
                            id="aadhar"
                            name="aadhar"
                            value={aadhar || ''}
                            onChange={handleChange4}
                            ref={inputRef2}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="state" className="form-label">State</label>
                        <select
                            value={state}
                            onChange={handleChange5}
                            className="form-control"
                            id="State"
                        >
                            <option value={state}>{state}</option>
                            {states.map((state, index) => (
                                <option key={index} value={state.state_name}>
                                    {state.state_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="city" className="form-label">City</label>
                        <select
                            value={city}
                            onChange={handleChange6}
                            className="form-control"
                            id="State"
                        >
                            <option value={flag===false?city:""}>{flag===false?city:"Select City"}</option>
                            {cities.map((city, index) => (
                                <option key={index} value={city.city_name}>
                                    {city.city_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="team_members" className="form-label">Team members</label>
                        <input
                            type="text"
                            className="form-control"
                            id="team_members"
                            name="team_members"
                            value={teamMembers || ''}
                            onChange={handleChange7}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            value={description || ''}
                            onChange={handleChange8}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="workExperience" className="form-label">Word Experience</label>
                        <input
                            type="number"
                            className="form-control"
                            id="workExperience"
                            name="workExperience"
                            value={workExperience || ''}
                            onChange={handleChange9}
                            ref={inputRef3}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="target_audience" className="form-label">Target Audience</label>
                        <input
                            type="text"
                            className="form-control"
                            id="target_audience"
                            name="target_audience"
                            value={targetAudience || ''}
                            onChange={handleChange10}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="form-check" className="form-label">Registered company ?</label>

                        <div className="form-check" id="formcheckid">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                value="Yes"
                                checked={registered === "Yes"}
                                onChange={handleChange11}
                                id="flexRadioDefault1"
                            />
                            <label className="form-check-label radio_label" htmlFor="flexRadioDefault1">
                                Yes
                            </label>

                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                value="No"
                                checked={registered === "No"}
                                onChange={handleChange11}
                                id="flexRadioDefault2"
                            />
                            <label className="form-check-label radio_label" htmlFor="flexRadioDefault2">
                                No
                            </label>
                        </div>
                    </div>

                    {registered === "Yes" && <div className="mb-3">
                        <label htmlFor="companyName" className="form-label">Name of Company <span className="star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            value={companyName || ''}
                            onChange={handleChange12}
                        />
                    </div>}
                    {registered === "Yes" && <div className="mb-3">
                        <label htmlFor="companyType" className="form-label">Company Type <span className="star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyType"
                            name="companyType"
                            value={companyType || ''}
                            onChange={handleChange13}
                        />
                    </div>}
                    {registered === "Yes" && <div className="mb-3">
                        <label htmlFor="other_details" className="form-label">Other Details <span className="star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="other_details"
                            name="other_details"
                            value={otherDetails || ''}
                            onChange={handleChange14}
                            required
                        />
                    </div>}
                    <div className="mb-3">
                        <label className="form-label">Please submit your pitch deck for the evaluation</label>
                        <input type="file" accept=".pdf" onChange={handleChange15} className="form-control file_field" id="pitch_deck" />
                        <div className="form_fields">

                            <label htmlFor="pitch_deck" className="link"><i className="fa-sharp fa-solid fa-file-arrow-up"></i></label>
                        </div>
                    </div>

                </form>
            </div>
            <div className="modal-footer">
                <button type="button" ref={closeRef} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveChanges} >Save changes</button>
            </div>
        </div>
    )
}

export default Modal
