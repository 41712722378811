import React, { useEffect, useState, useRef } from 'react'
import logo1 from '../images/Venture Vault.svg'
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {

    const [mainEmail, setMainEmail] = useState(null);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState();
    const [aadhar, setAadhar] = useState();
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [teamMembers, setTeamMembers] = useState("");
    const [desc, setDesc] = useState("");
    const [workExperience, setWorkExperience] = useState();
    const [targetAudience, setTargetAudience] = useState("");
    const [registered, setRegistered] = useState("No");
    const [companyName, setCompanyName] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [otherDetails, setOtherDetails] = useState("");
    const [pdfFile, setPdfFile] = useState(null);
    const [status, setStatus] = useState(0);

    function counting(num) {
        return String(num).split('').reduce(
            (count, digit) => count + 1, 0
        );
    }

    const handleChange1 = (e) => {
        setEmail(e.target.value);
    }

    const handleChange2 = (e) => {
        setName(e.target.value);
    }

    const handleChange3 = (e) => {
        setNumber(e.target.value);
    }

    const handleChange4 = (e) => {
        setAadhar(e.target.value);
    }

    const handleChange5 = (e) => {
        setState(e.target.value);
        getCities(e.target.value);
    }

    const handleChange6 = (e) => {
        setCity(e.target.value);
    }

    const handleChange7 = (e) => {
        setTeamMembers(e.target.value);
    }

    const handleChange8 = (e) => {
        setDesc(e.target.value);
    }

    const handleChange9 = (e) => {
        setWorkExperience(e.target.value);
    }

    const handleChange10 = (e) => {
        setTargetAudience(e.target.value);
    }

    const handleChange11 = (e) => {
        setRegistered(e.target.value);
    }

    const handleChange12 = (e) => {
        setCompanyName(e.target.value);
    }

    const handleChange13 = (e) => {
        setOtherDetails(e.target.value);
    }

    const handleChange14 = (e) => {
        setPdfFile(e.target.files[0]);
    }

    const handleChange16 = (e) => {
        setCompanyType(e.target.value);
    }

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token2')) {
            navigate('/student_login');
        }
    }, [navigate]);

    useEffect(() => {
        if (localStorage.getItem('token2')) {
            getStudentEmail();
        }
    }, []);

    const getStudentData = async (mail) => {
        console.log(mainEmail, mail);
        try {
            const response = await fetch(`https://wpi.cgc.ac.in/wp-json/myplugin/v1/get_student_alldata?email=${mail}`);
            // if (!response.ok) {
            //     throw new Error("Failed to fetch data");
            // }
            const data1 = await response.json();
            console.log(data1);
            // setData(data1);
            setName(data1.name);
            setEmail(data1.email);
            setNumber(data1.number);
            setState(data1.state);
            setCity(data1.city);
            setAadhar(data1.aadhar);
            data1.team_members && setTeamMembers(data1.team_members);
            setDesc(data1.description);
            setWorkExperience(data1.workExperience);
            setTargetAudience(data1.target_audience)
            data1.registered && setRegistered(data1.registered);
            data1.companyName && setCompanyName(data1.companyName);
            data1.companyType && setCompanyType(data1.companyType);
            data1.other_details && setOtherDetails(data1.other_details);
            setStatus(data1.status);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getStudentEmail = async () => {
        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/get_student_data", {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem('token2')
            }
        })
        const data1 = await response.json();
        console.log(data1);
        if (data1.data.success) {
            console.log("Authentication completed");
            setMainEmail(data1.email);
            getStudentData(data1.email);
            console.log("yes");
        }
        else {
            localStorage.removeItem('token2');
            throw Error("Invalid Authentication Token");
        }

    }

    const handleLogout = () => {
        localStorage.removeItem('token2');
        navigate('/student_login');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(mainEmail);

        if (counting(aadhar) != 12) {
            setAlert({ msg: "Invalid Aadhar Number", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("aadhar", aadhar);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("team_members", teamMembers);
        formData.append("desc", desc);
        formData.append("workExperience", workExperience);
        formData.append("target_audience", targetAudience);
        formData.append("registered", registered);
        formData.append("companyName", companyName);
        formData.append("companyType", companyType);
        formData.append("other_details", otherDetails);
        formData.append("pdf_file", pdfFile);

        const response = await fetch(`https://wpi.cgc.ac.in/wp-json/myplugin/v1/update_data?email=${mainEmail}`, {
            method: "POST",
            body: formData
        })

        const data = await response.json();
        console.log(data);

        if (data.status === "success") {
            setAlert({ msg: "Your profile completed successfully", type: "success" });
        }

        getStudentData();

        setTimeout(() => {
            setAlert(null);
        }, 2000);
    }

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        };

        const inputElement = inputRef.current;
        const inputElement2 = inputRef2.current;
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel);
            inputElement.addEventListener('mousewheel', handleWheel);
        }
        if (inputElement2) {
            inputElement2.addEventListener('wheel', handleWheel);
            inputElement2.addEventListener('mousewheel', handleWheel);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('wheel', handleWheel);
                inputElement.removeEventListener('mousewheel', handleWheel);
            }
            if (inputElement2) {
                inputElement2.removeEventListener('wheel', handleWheel);
                inputElement2.removeEventListener('mousewheel', handleWheel);
            }
        };
    }, []);


    return (
        <div>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
                    <div className="container-fluid">
                        <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand d-none d-lg-block py-lg-2 mb-lg-5 px-lg-6 me-0 customDesign" to="/">
                            <img src={logo1} alt="..." />
                        </Link>
                        <div className="navbar-user d-lg-none">
                            <div className="dropdown">
                                <Link to="/" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="avatar-parent-child">
                                        <i className="fa-solid fa-circle-user"></i>
                                        <span className="avatar-child avatar-badge bg-success"></span>
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                                    <Link to="/student_profile" className="dropdown-item">Change Password</Link>
                                    {/* <Link to="/" className="dropdown-item">Settings</Link>
                                    <Link to="/" className="dropdown-item">Billing</Link> */}
                                    <hr className="dropdown-divider" />
                                    <button onClick={handleLogout} className="dropdown-item">Logout</button>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="sidebarCollapse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link active " to="/student_dashboard">
                                        <i className="bi bi-house"></i> Profile
                                        &nbsp;
                                        <span className={status == 1 ? "completed" : "incomplete"}>{status == 1 ? " (completed)" : " (incomplete)"}</span>
                                    </Link>
                                </li>
                            </ul>

                            {/* <hr className="navbar-divider my-5 opacity-20" /> */}

                            <div className="mt-auto"></div>

                            <ul className="navbar-nav d-none d-lg-block">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/student_profile">
                                        <i className="bi bi-person-square"></i> Change Password
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="my-btng nav-link" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-left"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="h-screen flex-grow-1 overflow-y-lg-auto">

                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">


                            <div className="card shadow border-0 mb-7">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-4"><h5 className="mb-5 mb-md-0">{"Total Entries : " + data.length}</h5></div> */}

                                        {/* <div className="col-md-2 text-center mb-5 mb-md-0">
                                            <button type="button" style={{ width: "100%" }} onClick={handleExport} className="btn btn-primary">Export</button>
                                        </div> */}

                                        {/* <div className="col-md-6">
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search Name/Email/Phone no." value={searchTerm} onChange={handleChange} aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                    <button className="btn btn-outline-secondary" onClick={handleClick} type="submit" id="button-addon2">Search</button>
                                                </div>
                                            </form>
                                        </div> */}
                                        <h1>Student Details</h1>
                                    </div>
                                </div>
                                <section className="section form_container">
                                    {/* <div className="row"> */}
                                    <div className="form_sec">
                                        {/* <div className="col-12 "> */}
                                        <div className="inside_form_sec">

                                            <form onSubmit={handleSubmit}>
                                                <div className="row align-items-baseline">

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="email" className="form-label">Email <span className="star">*</span></label>
                                                            <input required type="email" readOnly value={email} onChange={handleChange1} placeholder="Your email address" className="form-control" id="email" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="name" className="form-label">Name of the applicant <span className="star">*</span></label>
                                                            <input required type="text" readOnly value={name} onChange={handleChange2} placeholder="Your answer" className="form-control" id="name" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="number" className="form-label">Mobile Number (WhatsApp) <span className="star">*</span></label>
                                                            <input required type="number" readOnly value={number} onChange={handleChange3} placeholder="Your answer" className="form-control" id="number" />
                                                        </div>

                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="State" className="form-label">State <span className="star">*</span></label>
                                                            <input required type="text" readOnly value={state} onChange={handleChange5} placeholder="Your answer" className="form-control" id="state" />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="City" className="form-label">City <span className="star">*</span></label>
                                                            <input required type="text" readOnly value={city} onChange={handleChange6} placeholder="Your answer" className="form-control" id="city" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="aadhar" className="form-label">Aadhar Number <span className="star">*</span></label>
                                                            <input required type="number" value={aadhar} onChange={handleChange4} ref={inputRef} placeholder="Your answer" className="form-control" id="aadhar" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="team_members" className="form-label">Team members details, if any (Name, Email, Ph No.) </label>
                                                            <input type="text" value={teamMembers} onChange={handleChange7} placeholder="Your answer" className="form-control" id="team_members" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="desc" className="form-label">Brief description of the Product/Services/ Technology/Business, you plan to submit (not more than in 1 page)<span className="star">*</span></label>
                                                            <textarea required value={desc} onChange={handleChange8} placeholder="Your answer" className="form-control" id="desc" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="working" className="form-label">How long have you been working on the idea?(in months)<span className="star">*</span></label>
                                                            <input required type="number" ref={inputRef2} value={workExperience} onChange={handleChange9} placeholder="Your answer" className="form-control" id="working" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="target_audience" className="form-label">Who is your target audience/customer?<span className="star">*</span></label>
                                                            <input required type="text" value={targetAudience} onChange={handleChange10} placeholder="Your answer" className="form-control" id="target_audience" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form_fields">
                                                            <label htmlFor="form-check" className="form-label">Are you are a registered company under companies act?<span className="star">*</span></label>

                                                            <div className="form-check" id="formcheckid">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="flexRadioDefault2"
                                                                    value="Yes"
                                                                    checked={registered === "Yes"}
                                                                    onChange={handleChange11}
                                                                    id="flexRadioDefault1"
                                                                />
                                                                <label className="form-check-label radio_label" htmlFor="flexRadioDefault1">
                                                                    Yes
                                                                </label>

                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="flexRadioDefault2"
                                                                    value="No"
                                                                    checked={registered === "No"}
                                                                    onChange={handleChange11}
                                                                    id="flexRadioDefault2"
                                                                />
                                                                <label className="form-check-label radio_label" htmlFor="flexRadioDefault2">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {registered === "Yes" && <div className="col-md-6"><div className="form_fields">
                                                        <label htmlFor="company" className="form-label">Name of Company <span className="star">*</span></label>
                                                        <input required type="text" value={companyName} onChange={handleChange12} placeholder="Your answer" className="form-control" id="company" />
                                                    </div></div>}

                                                    {registered === "Yes" && <div className="col-md-6"><div className="form_fields">
                                                        <label for="mention" className="form-label">Mention its type like Pvt Ltd. Or LLP. <span className="star">*</span></label>
                                                        <input required type="text" value={companyType} onChange={handleChange16} placeholder="Your answer" className="form-control" id="mention" />
                                                    </div></div>}

                                                    {registered === "Yes" && <div className="col-md-6"><div className="form_fields">
                                                        <label htmlFor="other_details" className="form-label">Any other details (award, achievement, patent etc.) which would help in evaluating your proposal<span className="star">*</span></label>
                                                        <input required type="text" value={otherDetails} onChange={handleChange13} placeholder="Your answer" className="form-control" id="other_details" />
                                                    </div></div>}

                                                    <div className="col-md-12">
                                                        <div className="form_fields">
                                                            <label className="form-label">Please submit your pitch deck for the evaluation <span className="star">*</span></label>
                                                            <input required type="file" accept=".pdf" onChange={handleChange14} className="form-control file_field" id="pitch_deck" />

                                                            <label htmlFor="pitch_deck" className="link"><i className="fa-sharp fa-solid fa-file-arrow-up"></i></label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form_fields">
                                                            <label className="form-label">Declaration <span className="star">*</span></label>

                                                            <div className="form-check flex_form_check">
                                                                <input className="form-check- flex_form_input" type="checkbox" required id="flexCheckDefault" />
                                                                <label className="form-check-label radio_label" htmlFor="flexCheckDefault">
                                                                    I/We hereby declare that all information along with mentioned documents & facts are true, correct to the best of my / our knowledge and nothing has been concealed so far.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <button type="submit" className="mybtn" id="btnid">Save</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
