import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginStyle.css';
import image1 from '../images/studentprofile.png';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/send_password_reset", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email })
        })

        const data = await response.json();
        console.log(data);

        if(data.code){
            alert(data.message)
        }
        else{
            navigate('/thanks');
        }

    }

    const handleChange1 = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div className="login-container1">
            <div className="login-container container">
                <div className="row align-items-center">
                    <div className="col-md-6 d-none d-md-block">
                        <img src={image1} alt="" />
                    </div>

                    <div className="col-md-6">
                        <h1>Forgot Password</h1>

                        <form className='form_style login_form' onSubmit={handleSubmit}>

                            <div className="form_fields">
                                <label htmlFor="Email" className="form-label">Your Email id</label>
                                <input required type="email" value={email} onChange={handleChange1} placeholder="Enter Email" className="form-control" id="Email" />
                            </div>

                            <button type="submit" className="mybtn2" id="btnid">Submit</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}