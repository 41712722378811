import React, { useEffect, useState } from 'react';
import './LoginStyle.css';
import image1 from '../images/studentprofile.png';

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alert, setAlert] = useState(null);

    const [token, setToken] = useState(null);

    useEffect(() => {
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const tokenValue = urlParams.get('token');

        setToken(tokenValue);
    }, []);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/student_password_reset", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify({ newPassword: newPassword, confirmPassword: confirmPassword })
        })

        const data1 = await response.json();
        console.log(data1);

        if(data1.data.success){
            setAlert({ msg: data1.message, type: "success"});
        }
        else{
            setAlert({ msg: data1.message, type: "danger"});
        }


        setTimeout(() => {
            setAlert(null);
        }, 2000);
    }

    const handleChange2 = (e) => {
        setNewPassword(e.target.value);
    }

    const handleChange3 = (e) => {
        setConfirmPassword(e.target.value);
    }

    return (
        <div className="login-container1">
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}
            
            <div className="login-container container">
                <div className="row align-items-center">
                    <div className="col-md-6 d-none d-md-block">
                        <img src={image1} alt="" />
                    </div>

                    <div className="col-md-6">
                        <h1>Change Password</h1>

                        <form className='form_style login_form' onSubmit={handlePasswordChange}>

                            <div className="form_fields">
                                <label htmlFor="newPassword" className="form-label">New Password</label>
                                <input required type='password' value={newPassword} onChange={handleChange2} placeholder="Enter New Password" className="form-control" id="newPassword" />
                            </div>

                            <div className="form_fields">
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                <input required type='password' value={confirmPassword} onChange={handleChange3} placeholder="Confirm New Password" className="form-control" id="confirmPassword" />
                            </div>

                            <button type="submit" className="mybtn2" id="btnid">Update</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}