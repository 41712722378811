import React, { useEffect, useState } from 'react';
import './LoginStyle.css';
import { useNavigate } from 'react-router-dom';
import image1 from '../images/adminlogin.png';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate('/admin_dashboard');
        }
    }, [navigate])

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username: username, password: password })
        });
        const data1 = await response.json();
        console.log(data1);

        if(data1.data.success){
            localStorage.setItem('token',data1.auth_token);
            navigate('/admin_dashboard');
        }
        else{
            alert(data1.message);
        }

    }

    const handleChange1 = (e) => {
        setUsername(e.target.value);
        console.log(username);
    }

    const handleChange2 = (e) => {
        setPassword(e.target.value);
        console.log(password);
    }

    return (
        <div className="login-container1">
            <div className="login-container container">
                <div className="row align-items-center">
                    <div className="col-md-6 d-none d-md-block">
                        <img src={image1} alt="" />
                    </div>

                    <div className="col-md-6">
                        <h1>Admin Login</h1>

                        <form className='form_style login_form' onSubmit={handleLogin}>

                            <div className="form_fields">
                                <label htmlFor="Username" className="form-label">Username</label>
                                <input required type="username" value={username} onChange={handleChange1} placeholder="Enter Username" className="form-control" id="Username" />
                            </div>

                            <div className="form_fields">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input required type='password' value={password} onChange={handleChange2} placeholder="Enter Password" className="form-control" id="password" />
                            </div>

                            <button type="submit" className="mybtn2" id="btnid">Login</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}