
import image1 from '../images/CGC-Jhanjeri-Logo.svg';
import image2 from '../images/cgcj-naaca+ (1) 1.svg';
import image3 from '../images/12-crore-cgc-josh 1.svg';
import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function Navbar() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <header className="sticky_nav">
                <div className="container">
                    <nav className="nav">
                        <Link to="/" className="logo">
                            <img src={image1} alt="" />
                        </Link>
                        <Link className="logo1">
                            <img src={image2} alt="" />
                        </Link>
                        <Link className="logo2">
                            <div className="dropdown">
                                <button className="btn mybtn2 btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Login
                                </button>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to='/admin_login'>Login as Admin</Link></li>
                                    <li><Link className="dropdown-item" to="/student_login">Login as Student</Link></li>
                                </ul>
                            </div>
                            {/* <button className="mybtn2" id="btnid">Login</button> */}
                        </Link>
                    </nav>
                </div>
            </header>
        </div>
    )
}
