import React, { useEffect, useState } from 'react'
import logo1 from '../images/Venture Vault.svg'
import { Link, useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Modal from './Modal';
import ConfirmModal from './ConfirmModal';

const Dashboard2 = () => {

    const SECRET_CODE = 'crest@123';

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [alert, setAlert] = useState(null);
    const [filterExport, setFilterExport] = useState("");
    const [filterExport2, setFilterExport2] = useState("");
    const [filterProfile, setFilterProfile] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/admin_login');
        }
    }, [navigate]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getUserData();
            getUsers();
        }
    }, [currentPage]);

    const getUserData = async () => {
        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/get_user_data", {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem('token')
            }
        })
        const data1 = await response.json();
        console.log(data1);
        if (data1.data.success) {
            console.log("Authentication completed");
        }
        else {
            localStorage.removeItem('token');
            throw Error("Invalid Authentication Token");
        }

    }

    const getUsers = async () => {
        try {
            console.log(currentPage)
            const response = await fetch(`https://wpi.cgc.ac.in//wp-json/myplugin/v1/get-data?page=${currentPage - 1}`);
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data1 = await response.json();
            setData(data1.result);
            setTotalPages(data1.total_pages)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        console.log('Selected Page:', page);
    };

    const handleDownload = (pdfUrl) => {
        if (pdfUrl === null) {
            setAlert({ msg: "pdf is not uploaded", type: "danger" })

            setTimeout(() => {
                setAlert(null);
            }, 2000);
        }
        else {
            window.open(pdfUrl, '_blank');
        }
    }

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === "") {
            setFilterExport("");
            getUsers();
        }
    }

    const handleClick = async () => {
        setFilterExport(searchTerm);
        if (searchTerm === "") {
            console.log("invalid search");
        }
        else {
            const response = await fetch(`https://wpi.cgc.ac.in/wp-json/myplugin/v1/search-data?search=${searchTerm}`);
            const data1 = await response.json();
            console.log(data1);
            if (!data1[0].success) {
                setData(data1);
                setAlert({ msg: "Search Completed", type: "success" });
            }
            else {
                setAlert({ msg: data1[0].message, type: "danger" });
            }

            setTimeout(() => {
                setAlert(null);
            }, 2000);
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin_login');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const [allChecked, setAllChecked] = useState(false);
    const [checkedRows, setCheckedRows] = useState(
        data.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {})
    );

    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setAllChecked(isChecked);
        setCheckedRows(data.reduce((acc, _, index) => {
            acc[index] = isChecked;
            return acc;
        }, {}));
    };

    const handleRowCheckboxChange = (index) => (event) => {
        const isChecked = event.target.checked;
        setCheckedRows(prevCheckedRows => {
            const updatedCheckedRows = {
                ...prevCheckedRows,
                [index]: isChecked
            };

            const allCheckedNow = Object.values(updatedCheckedRows).every(value => value);
            setAllChecked(allCheckedNow);

            return updatedCheckedRows;
        });
    };

    // Optionally, if you want to handle initial render when data changes
    useEffect(() => {
        setCheckedRows(data.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {}));
        setAllChecked(false);
    }, [data]);


    const handleExport = async () => {
        try {
            let apiUrl = 'https://wpi.cgc.ac.in/wp-json/myplugin/v1/export-csv';

            if (filterExport !== "") {
                console.log(filterExport)
                apiUrl = `https://wpi.cgc.ac.in/wp-json/myplugin/v1/search/export-csv?search=${filterExport}`;
            }

            if (filterExport2 !== "") {
                console.log(filterExport2)
                apiUrl = `https://wpi.cgc.ac.in/wp-json/myplugin/v1/filter/export-csv?filter=${filterExport2}`;
            }

            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            let filename = 'exported_data.csv';

            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            const blob = await response.blob();

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading CSV:', error);
            setAlert({ msg: "No matching data found.", type: "danger" })

            setTimeout(() => {
                setAlert(null);
            }, 2000);
        }
    };

    const getProfileFilterData = async (filter) => {
        setFilterExport2(filter);
        const response = await fetch(`https://wpi.cgc.ac.in/wp-json/myplugin/v1/filter-data?filter=${filter}`);
        const data = await response.json();
        console.log(data);
        if (data.code) {
            setData([]);
            setAlert({ msg: "No data found with the specified filter.", type: "danger" });
        }
        else {
            console.log("no")
            setData(data);
            setAlert({ msg: "Here are the results", type: "success" });
        }

        setTimeout(() => {
            setAlert(null);
        }, 2000);
    }

    const handleFilterChange = (e) => {
        setFilterProfile(e.target.value);

        if (e.target.value === "") {
            setFilterExport2("");
            getUsers();
            setAlert({ msg: "Filter Cleared", type: "success" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
        }
        else {
            getProfileFilterData(e.target.value);
        }
    }

    const handleDelete = () => {

        const checkedEmails = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].email);

        if (checkedEmails.length > 0) {
            checkedEmails.forEach(email => console.log(email));
        } else {
            console.log("No rows selected.");
            setAlert({ msg: "No rows selected", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal2'));
        modal.show();
    }

    const handleConfirmDelete = async (code) => {

        console.log(code);

        if (code !== SECRET_CODE) {
            setAlert({ msg: "Invalid Secret Code", type: "danger" })

            setTimeout(() => {
                setAlert(null);
            }, 2000);

            return;
        }

        const checkedEmails = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].email);

        const response = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/remove_student_data", {
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ emails: checkedEmails })
        })

        const data1 = await response.json();
        console.log(data1);

        if (data1.success) {
            setAlert({ msg: "Deleted successfully", type: "success" })
            getUsers();
        }
        else {
            setAlert({ msg: "Unable to delete the data", type: "danger" })
        }

        setTimeout(() => {
            setAlert(null);
        }, 2000);
    };

    // Edit data in modal

    const [selectedRow, setSelectedRow] = useState(null);

    const handleEditClick = (row) => {
        setSelectedRow(row);
        // const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));

        const modalId = 'exampleModal';
        const modalElement = document.getElementById(modalId);
        const modalInstance = new window.bootstrap.Modal(modalElement);

        // Add a unique key to the modal to force remount
        modalElement.setAttribute('data-key', Date.now());

        modalInstance.show();
    };

    const changeAlert = () => {
        getUsers();
        setAlert({ msg: "Details Updated Successfully", type: "success" });

        setTimeout(() => {
            setAlert(null);
        }, 2000);
    }

    return (
        <div>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
                    <div className="container-fluid">
                        <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand d-none d-lg-block py-lg-2 mb-lg-5 px-lg-6 me-0 customDesign" to="/">
                            <img src={logo1} alt="..." />
                        </Link>
                        <div className="navbar-user d-lg-none">
                            <div className="dropdown">
                                <Link to="/" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="avatar-parent-child">
                                        <i className="fa-solid fa-circle-user"></i>
                                        <span className="avatar-child avatar-badge bg-success"></span>
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                                    <Link to="/profile" className="dropdown-item">Change Password</Link>
                                    {/* <Link to="/" className="dropdown-item">Settings</Link>
                                    <Link to="/" className="dropdown-item">Billing</Link> */}
                                    <hr className="dropdown-divider" />
                                    <Link onClick={handleLogout} className="dropdown-item">Logout</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="sidebarCollapse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link active" to="/admin_dashboard">
                                        <i className="bi bi-house"></i> Venture Registrations
                                    </Link>
                                </li>
                            </ul>

                            {/* <hr className="navbar-divider my-5 opacity-20" /> */}

                            <div className="mt-auto"></div>

                            <ul className="navbar-nav d-none d-lg-block">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">
                                        <i className="bi bi-person-square"></i> Change Password
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="my-btng nav-link" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-left"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="h-screen flex-grow-1 overflow-y-lg-auto">

                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">


                            <div className="card shadow border-0 mb-7">
                                <div className="card-header">
                                    <div className="row align-items-center">

                                        <div className="col-md-2"><h5 className="mb-5 mb-md-0">{"Entries : " + data.length}</h5></div>

                                        <div className="col-md-2 col-6 text-center mb-5 mb-md-0">
                                            <button type="button" onClick={handleExport} className="btn btn-primary">Export</button>
                                        </div>

                                        <div className="col-md-1 col-6 text-center mb-5 mb-md-0 p-0">
                                            <button type="button" className="btn btn-danger" onClick={handleDelete}><i className="fa-solid fa-trash"></i></button>
                                        </div>

                                        <div className="col-md-3 mb-5 mb-md-0">
                                            <select className="form-select my-form-select" aria-label="Default select example" value={filterProfile} onChange={handleFilterChange}>
                                                <option selected value="">Filter (Profile)</option>
                                                <option value="completed">Completed</option>
                                                <option value="incomplete">Incomplete</option>
                                            </select>
                                        </div>

                                        <div className="col-md-4">
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search Name/Email/Phone no." value={searchTerm} onChange={handleChange} aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                    <button className="btn btn-outline-secondary" onClick={handleClick} type="submit" id="button-addon2">Search</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover table-nowrap">
                                        <thead className="thead-light">
                                            <tr>
                                                <th><form><input type="checkbox" checked={allChecked} onChange={handleHeaderCheckboxChange} /></form></th>
                                                <th>Edit</th>
                                                <th>S.No.</th>
                                                <th>Status</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Number</th>
                                                <th>Aadhar</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Team Members</th>
                                                <th>Description</th>
                                                <th>Work Experience</th>
                                                <th>Target Audience</th>
                                                <th>Registered</th>
                                                <th>Company Name</th>
                                                <th>Company type</th>
                                                <th>Other Details</th>
                                                <th>pdf file</th>
                                                <th>Password</th>
                                                <th>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row, index) => (
                                                <tr key={index}>
                                                    <td><input type="checkbox" checked={checkedRows[index]} onChange={handleRowCheckboxChange(index)} /></td>

                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn-1"
                                                            onClick={() => handleEditClick(row)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square"></i>
                                                        </button>
                                                    </td>

                                                    <td>{index + 1}</td>
                                                    <td><span className={row.status == 1 ? "completed" : "incomplete"}>{row.status == 1 ? "completed" : "incomplete"}</span></td>
                                                    <td>{row.name}</td>
                                                    <td>{row.email}</td>
                                                    <td>{row.number}</td>
                                                    <td>{row.aadhar || '-'}</td>
                                                    <td>{row.state}</td>
                                                    <td>{row.city}</td>
                                                    <td>{row.team_members || '-'}</td>
                                                    <td>{row.description || '-'}</td>
                                                    <td>{row.workExperience || '-'}</td>
                                                    <td>{row.target_audience || '-'}</td>
                                                    <td>{row.registered || 'No'}</td>
                                                    <td>{row.companyName || '-'}</td>
                                                    <td>{row.companyType || '-'}</td>
                                                    <td>{row.other_details || '-'}</td>
                                                    <td><button type="button"
                                                        className="btn btn-sm btn-square btn-neutral text-danger-hover" disabled={!row.pdf_file} onClick={() => handleDownload(row.pdf_file)}><i className="fa-solid fa-download"></i></button>
                                                    </td>
                                                    <td>{row.password}</td>
                                                    <td>{row.time}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Pagination currentPage={currentPage} totalPages={totalPages} onChange={handlePageChange} />
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Student Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {selectedRow && (<Modal changeAlert={changeAlert} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />)}

                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Delete Data</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <ConfirmModal handleConfirmDelete={handleConfirmDelete} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard2
