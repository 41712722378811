import React, { useState, useEffect, useRef } from 'react'
import { json, useNavigate } from 'react-router-dom';

const Form2 = () => {

    const [alert, setAlert] = useState(null);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState();
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const navigate = useNavigate();

    const [authToken, setAuthToken] = useState(null);
    const [authTokenExpiry, setAuthTokenExpiry] = useState(null);

    function counting(num) {
        return String(num).split('').reduce(
            (count, digit) => count + 1, 0
        );
    }

    useEffect(() => {
        getAccessToken();
    }, []);

    const getAccessToken = async () => {
        try {
            const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
                headers: {
                    "api-token": "QcbmmQtxMFEXBbZN0OnFjG1lpJaOf1FSROjj5K9WMkJwkllhMQBkUIXv70S1aqabX2k",
                    "user-email": "shivduttdhadwal@gmail.com",
                    Accept: "application/json",
                },
            });

            const data = await response.json();
            setAuthToken('Bearer ' + data.auth_token);
            setAuthTokenExpiry(new Date().getTime() + (4 * 60 * 60 * 1000));

        }
        catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (authToken && authTokenExpiry && new Date().getTime() > authTokenExpiry) {
            getAccessToken();
        }
    }, [authToken, authTokenExpiry]);


    useEffect(() => {
        getStates();
    }, [authToken])

    const getStates = async () => {

        if (!authToken) {
            console.error('AuthToken is null, cannot fetch states');
            return;
        }

        const response = await fetch("https://www.universal-tutorial.com/api/states/india",
            {
                headers: {
                    Authorization: authToken,
                    Accept: "application/json"
                }
            }
        )

        const data = await response.json();
        console.log(data);
        setStates(data);
    }

    const getCities = async (updatedState) => {
        try {
            const response = await fetch(`https://www.universal-tutorial.com/api/cities/${updatedState}`,
                {
                    headers: {
                        Authorization: authToken,
                        Accept: "application/json"
                    }
                }
            )

            const data = await response.json();
            console.log(data);
            setCities(data);
        }
        catch (error) {
            console.error('Error fetching cities:', error);
        }
    }

    const formatWithLeadingZero = (number) => {
        return number < 10 ? '0' + number : number;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(counting(number) != 10){
            setAlert({ msg: "Invalid Phone Number", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        const currentDateObj = new Date();
        const day = formatWithLeadingZero(currentDateObj.getDate());
        const month = formatWithLeadingZero(currentDateObj.getMonth() + 1);
        const year = currentDateObj.getFullYear();
        let hours = currentDateObj.getHours();
        const minutes = formatWithLeadingZero(currentDateObj.getMinutes());
        const period = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        const password = Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111;
        console.log(password);

        const formattedDate = `${day}/${month}/${year} ${formatWithLeadingZero(hours)}:${minutes} ${period}`;

        const formData = new FormData();
        formData.append("email", email);
        formData.append("name", name);
        formData.append("number", number);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("time", formattedDate);
        formData.append("password", password);

        console.log([...formData]);

        const response1 = await fetch("https://wpi.cgc.ac.in/wp-json/myplugin/v1/store-data", {
            method: "POST",
            body: formData
        });
        const data1 = await response1.json();
        console.log(data1);

        if (data1.data.success) {
            setAlert({ msg: "Thank you for the registration", type: "success" });
            navigate("/thankyou");
        }
        else {
            setAlert({ msg: data1.message, type: "danger" });
        }

        setTimeout(() => {
            setAlert(null);
        }, 2000);

    }

    const handleChange1 = (e) => {
        setEmail(e.target.value);
    }

    const handleChange2 = (e) => {
        setName(e.target.value);
    }

    const handleChange3 = (e) => {
        setNumber(e.target.value);
        console.log(counting(e.target.value));
    }

    // const handleChange4 = (e) => {
    //     setAadhar(e.target.value);
    // }

    const handleChange5 = (e) => {
        setState(e.target.value);
        getCities(e.target.value);
    }

    const handleChange6 = (e) => {
        setCity(e.target.value);
    }

    const inputRef = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        };

        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel);
            inputElement.addEventListener('mousewheel', handleWheel);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('wheel', handleWheel);
                inputElement.removeEventListener('mousewheel', handleWheel);
            }
        };
    }, []);

    return (
        <div className='banner_form'>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <h3>Register Today</h3>
            <form className='form_style' onSubmit={handleSubmit}>

                <div className="form_fields">
                    <input required type="text" value={name} onChange={handleChange2} placeholder="Name" className="form-control" id="name" />
                </div>

                <div className="form_fields">
                    <input required type="email" value={email} onChange={handleChange1} placeholder="Email" className="form-control" id="email" />
                </div>

                <div className="form_fields">
                    <input required type="number" ref={inputRef} value={number} onChange={handleChange3} placeholder="Phone No." className="form-control" id="number" />
                </div>

                <div className="form_fields">
                    <select
                        required
                        value={state}
                        onChange={handleChange5}
                        className="form-control"
                        id="State"
                    >
                        <option value="">State</option>
                        {states.map((state, index) => (
                            <option key={index} value={state.state_name}>
                                {state.state_name}
                            </option>
                        ))}
                    </select>

                </div>

                <div className="form_fields">
                    <select
                        required
                        value={city}
                        onChange={handleChange6}
                        className="form-control"
                        id="State"
                    >
                        <option value="">City</option>
                        {cities.map((city, index) => (
                            <option key={index} value={city.city_name}>
                                {city.city_name}
                            </option>
                        ))}
                    </select>
                </div>

                <button type="submit" className="mybtn2" id="btnid">Register Now</button>

            </form>
        </div>
    )
}

export default Form2
