import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import image1 from '../images/Group 2544.png'
import image2 from '../images/Line 9 (Stroke).svg'
import { Link } from 'react-router-dom'

const Thanks2 = () => {
    return (
        <div>
            <Navbar />

            <section className="spacing">
                <div className="container content">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className='thank_heading' >
                                <span className="span_heading thanks_title">
                                    Thank you
                                </span>
                                <img src={image1} alt="" />
                            </h2>
                            <div className="content_text">
                                <p>
                                    Please check your Email for Password Reset Link.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default Thanks2
