
import image11 from '../images/Vector 1.svg';
import image12 from '../images/iiclogo-1 1.png';
import image13 from '../images/Line 10.png';
import image14 from '../images/cgc_venturenest_logo_n1 2.png';
import image15 from '../images/banner_img.png';
import image16 from '../images/iiclogo-1 1.png';
import image17 from '../images/Line 10.png';
import image18 from '../images/cgc_venturenest_logo_n1 2.png';
import image19 from '../images/Mask group (5) 1.png';
import image20 from '../images/Vector 5.png';
import image21 from '../images/Vector 9.png';
import image22 from '../images/Group 2544.png';
import image23 from '../images/Line 9 (Stroke).svg';
import image24 from '../images/images.png';
import image25 from '../images/Vector.svg';
import image26 from '../images/Vector 6.png';
import image27 from '../images/Vector 7.png';
import image28 from '../images/content_img.png';
import image29 from '../images/Vector 6 (1).png';
import image30 from '../images/Vector 8.png';
import image31 from '../images/circleElement.svg';
import image32 from '../images/placeholder.png';
import image33 from '../images/Group 2544.png';
import image34 from '../images/images1.png';
import image35 from '../images/Ellipse 310.svg';
import image36 from '../images/Ellipse 311.svg';
import image37 from '../images/Group 1171274834.svg';
import image38 from '../images/Group.svg';
import image39 from '../images/Vector (1).svg';
import image40 from '../images/Group (1).svg';
import image41 from '../images/Rectangle 3464049.png';
import image42 from '../images/Rectangle 3464050.png';
import image43 from '../images/Rectangle 3464051.png';

import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Form2 from './Form2';
import { useEffect, useState } from 'react';


export default function Home() {

  const [scrollY, setScrollY] = useState(0);
  const [shouldAddClass, setShouldAddClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      console.log(window.scrollY);

      if (window.scrollY > 100) {
        setShouldAddClass(true);
      } 
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <>

      <section className="banner_sec">
        <Navbar />
        <div className="container form_container"><Form2 /></div>
        
        <div className="container banner">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="heading"><span className="span_heading">Venture Vault :</span> Unlock Funding Opportunities for Your Startup Idea
              </h1>

              <h3>Holistic Support for Budding Entrepreneurs and Startups</h3>
              <div className="row align-items-center">
                <div className="col-6 banner_footer">
                  <img src={image12} alt="" />
                </div>
                <div className="col-1 text-center">
                  <img className="img_width" src={image13} alt="" />
                </div>
                <div className="col-5">
                  <img src={image14} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 d-md-block d-none">
              <img src={image15} alt="" />
            </div>
          </div>
        </div>

        <img className="color1" src={image20} alt="" />
        <img className="color2" src={image21} alt="" />

      </section>

      <section className="study_sec spacing">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2>Venture Vault: Fueling Dreams, Funding Reality</h2>
              <p>
                VentureNest proudly introduces Venture Vault, a national-level event inspired by the renowned Shark Tank India. Venture Vault is your premier platform for showcasing innovative ideas, connecting with investors, and celebrating entrepreneurial talent nationwide.
              </p>
              <p>
                Venture Vault is more than just a pitch event; it's a vibrant ecosystem designed to nurture and propel startups to new heights. By participating, you unlock funding opportunities for your startup idea and gain invaluable exposure.
              </p>
              <p>
                Are you an ambitious entrepreneur with a groundbreaking startup idea? Venture Vault is here to help you turn your vision into reality. Join us for an exclusive event where you'll have the chance to pitch your idea to top investors, gain valuable insights from industry experts, and network with like-minded innovators.
              </p>
              <p>
                Don't miss out on this incredible opportunity. Booming entrepreneurs, join us and take the first step towards transforming your startup dreams into success!
              </p>
            </div>
            <div className={`col-lg-6 text-center`}>
              <img src={image28} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="new_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="row flex-row align-items-center">
                <div className="col-md-6 mb-5 mb-md-0 order-md-1 order-2">
                  <img src={image41} alt="" />
                </div>
                <div className="col-md-6 order-md-2 order-1">
                  <div className="side_content side1 text-md-end">
                    <h3 className='mb-lg-3 mb-2'>Showcase Your Innovations </h3>
                    <p>At Venture Vault, startups have the unique opportunity to present their groundbreaking ideas to a panel of esteemed investors and industry leaders. This exposure helps gain valuable feedback and increases visibility and credibility by putting your startup in front of a national audience.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="side_content side2">
                    <h3 className='mb-lg-3 mb-2'>Connect with Investors </h3>
                    <p>The event provides ample opportunities to connect with investors. Meet and network with top investors actively seeking the next big thing and unlock opportunities to secure funding that can take your startup to the next level.</p>
                  </div>
                </div>
                <div className="col-md-6 mb-5 mb-md-0">
                  <img src={image42} alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="row align-items-center">
                <div className="col-md-6 mb-5 mb-md-0 order-md-1 order-2">
                  <img src={image43} alt="" />
                </div>
                <div className="col-md-6 order-md-2 order-1">
                  <div className="side_content side3 text-md-end">
                    <h3 className='mb-lg-3 mb-2'>Celebrate Entrepreneurial Talent </h3>
                    <p>Venture Vault is also a celebration of entrepreneurial talent. Be recognised for your entrepreneurial spirit and innovative ideas, and compete for awards and prizes celebrating your hard work and creativity. This event aims to foster a supportive and dynamic startup ecosystem, providing the resources and connections necessary for startups to thrive and succeed.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex_content spacing">
        <div className="container">
          <h2 className="text-center mb-4">
            Event <span className="span_heading">Segments</span>
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 mb-4">
              <div className="flex_content_items">
                <h4>Pitch Deck Competition</h4>
                <p>Showcase your innovative ideas to a panel of seasoned investors and industry experts for a chance to secure funding and mentorship. This competition will highlight the brightest minds and the most promising startups, giving them a platform to present their business concepts and strategies.</p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="flex_content_items">
                <h4>Start-up Exhibition</h4>
                <p>Witness the latest innovations and entrepreneurial ventures from top talents nationwide, including esteemed participants from nearby schools and colleges. The exhibition will feature a diverse range of startups, offering attendees a glimpse into the future of various industries.</p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="flex_content_items">
                <h4>Keynote Sessions & Panel Discussions</h4>
                <p>Gain insights from renowned thought leaders, successful entrepreneurs, and investors through enlightening keynote sessions and engaging panel discussions. These sessions will cover various topics, providing valuable knowledge and inspiration to aspiring entrepreneurs and established business leaders alike.</p>

              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="flex_content_items">
                <h4>Investors Meet & Networking</h4>
                <p>Connect with potential investors, mentors, and industry leaders to explore collaboration opportunities and expand your professional network. This segment is designed to facilitate meaningful interactions, helping startups find the right partners and resources to accelerate their growth.</p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="flex_content_items">
                <h4>Awards for the Top Rising Start-ups</h4>
                <p>Celebrate the top rising startups and visionary entrepreneurs shaping innovation's future. Awards will be presented in various categories, recognising outstanding achievements and groundbreaking ideas potentially transforming industries.</p>
              </div>
            </div>
          </div>
        </div>
        <img src={image29} alt="" className="color5" />
        <img src={image30} alt="" className="color6" />
      </section>

      {/* <section className="spacing startup_sec">
        <div className="container my_container">
          <div className="row justify-content-center">
            <div className="col-lg-8 mb-4 relate">
              <div>
                <h2>
                  Building the
                  <span className="span_heading">
                    Startup Ecosystem
                  </span>
                </h2>
                <p>Venture Vault aims to create a thriving startup ecosystem in the region by:</p>
                <ul>
                  <li>
                    <p>
                      <b>Providing Exposure: </b>
                      Startups will gain visibility and recognition on a national
                      platform, attracting potential investors, partners, and customers.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Facilitating Growth: </b>
                      Through mentorship, networking, and funding opportunities,
                      startups
                      will receive the support they need to grow and scale their businesses.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Fostering Innovation: </b>
                      The event will inspire creativity and innovation, encouraging
                      more
                      individuals to pursue entrepreneurial ventures.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Strengthening Collaboration: </b>
                      By bringing together startups, investors, mentors, and
                      industry experts, VENTUREVAULT will foster a collaborative environment where ideas
                      can
                      flourish and partnerships can thrive.
                    </p>
                  </li>
                </ul>
                <div className="dot_pic">
                  <img src={image31} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 image1 align-items-center">
              <div className="pic">
                <img src={image32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="spacing last_sec">
        <div className="container join_sec">
          <div className="row">
            <div className="col-12 text-center">
              <h2>
                <span className="sm-heading">Join Us at</span>
                <br />
                <span className="span_heading">
                  Venture Vault
                </span>

                <img src={image33} alt="" />
              </h2>
              <p>Be a part of Venture Vault and experience the future of innovation. Whether you are an aspiring
                entrepreneur, an established startup, an investor, or simply someone passionate about
                innovation, Venture Vault offers something for everyone. Together, we can build a vibrant
                startup ecosystem and drive economic growth in the region.</p>
            </div>
          </div>
        </div>
        <img src={image30} alt="" className="color7" />
      </section> */}

      <section className='startup_sec'>
        <div className="container ">
          <h2 className='text-center mb-sm-4 mb-3'>
            Building the <span className="span_heading">Startup Ecosystem</span>
          </h2>

          <p className='text-center mb-4'>Venture Vault aims to create a thriving startup ecosystem in the region by:</p>

          <div className="row">
            <div className="col-lg-3 mb-4 col-sm-6">
              <div className="box box1">
                <div className="img_logo"><img src={image35} alt="" /><img className="inside_img" src={image37} alt="" /></div>
                <h4>Providing Exposure</h4>
                <p>Startups will gain visibility and recognition on a national platform, attracting potential investors, partners, and customers.</p>

              </div>
            </div>
            <div className="col-lg-3 mb-4 col-sm-6">
              <div className="box box2">
                <div className="img_logo"><img src={image36} alt="" /><img className="inside_img" src={image38} alt="" /></div>
                <h4>Facilitating Growth</h4>
                <p>Startups will receive the support they need to grow and scale their businesses through mentorship, networking, and funding opportunities.</p>

              </div>
            </div>
            <div className="col-lg-3 mb-4 col-sm-6">
              <div className="box box3">
                <div className="img_logo"><img src={image35} alt="" /><img className="inside_img" src={image39} alt="" /></div>
                <h4>Fostering Innovation</h4>
                <p>The event will inspire creativity and innovation, encouraging more individuals to pursue entrepreneurial ventures.</p>

              </div>
            </div>
            <div className="col-lg-3 mb-4 col-sm-6">
              <div className="box box4">
                <div className="img_logo"><img src={image36} alt="" /><img className="inside_img" src={image40} alt="" /></div>

                <h4>Strengthening Collaboration</h4>
                <p>By bringing together startups, investors, mentors, and industry experts, VENTUREVAULT will foster an environment where ideas and partnerships can flourish.</p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='join_section'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-md-0 mb-4 join_out1">
              <div className='join_content1'>
              <h2 className='mb-lg-4 mb-3'><span className="sm-heading">Join Us at</span>
                <br />
                Venture Vault
              </h2>
              <p>Be a part of Venture Vault and experience the future of innovation. Venture Vault offers something for everyone, whether you are an aspiring entrepreneur, an established startup, an investor, or simply someone passionate about innovation. With Venture Vault providing investments to startups, together we can build a vibrant startup ecosystem and drive economic growth in the region.</p>
              </div>
            </div>

            <div className="col-md-6 join_out2">
              <div className='join_content2'>
              <h2 className='mb-lg-4 mb-3'>About
                <br />
                <span className="span_heading">CGC Jhanjeri</span>
              </h2>
              <p>Located in the heart of Punjab, Chandigarh Group of Colleges (CGC), Jhanjeri is a premier educational institution dedicated to nurturing talent and fostering innovation. Our state-of-the-art campus, world-class faculty, and commitment to excellence make CGC Jhanjeri a hub for aspiring professionals and entrepreneurs.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <Footer />
      </footer>

    </>
  )
}
